<template>
  <div style="margin-top: 65px;">
    <div v-if="dataFetched" class="searched-contents-container">
      <div v-if="searchDatabase.length && Array.isArray(searchDatabase)">
        <h2 class="search-header-code" style="font-size:19px;">
          {{ $t("Showing Results") }}
        </h2>
        <div class="page-center">
          <div class="row-item-continer">
            <div
              v-for="(content, index) in searchDatabase"
              :key="index"
              class="card-container"
            >
              <!-- <SearchSlider :types="item" :localLang="localDisplayLang"></SearchSlider> -->
              <categoryCard
                :content="content"
                :displayTags="false"
                :displayContentData="contentDataShow"
                :cardType="fetchCardType"
              >
              </categoryCard>
            </div>
          </div>
          <div id="intersectPlaceholder">
            <categoriesPlaceholder :screen="placeholderScreenData"></categoriesPlaceholder>
          </div>
        </div>
      </div>
      <div v-if="Array.isArray(searchDatabase) && searchDatabase.length == 0">
        <h1 class="search-header-code" style="font-size:23px; opacity: 0.5;">
          {{ $t("No such content") }}
        </h1>
        <h2 :class="isHeaderVisible ? 'header-visible' : 'header-invisible'">
          {{ $t("Popular Searches") }}
        </h2>
        <div class="page-center">
          <div class="row-item-continer">
            <div
              v-for="(content, index) in popularSearch"
              :key="index"
              class="card-container"
            >
              <categoryCard
                :content="content"
                :displayTags="false"
                :displayContentData="contentDataShow"
                :cardType="fetchCardType"
              >
              </categoryCard>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!Array.isArray(searchDatabase)">
        <h2 :class="isHeaderVisible ? 'header-visible' : 'header-invisible'">
          {{ $t("Popular Searches") }}
        </h2>

        <div class="page-center">
          <div class="row-item-continer">
            <div
              v-for="(content, index) in popularSearch"
              :key="index"
              class="card-container"
            >
              <categoryCard
                :content="content"
                :title="content.title"
                :displayTags="false"
                :displayContentData="contentDataShow"
                :cardType="fetchCardType"
              >
              </categoryCard>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!dataFetched">
      <detailPageLoader></detailPageLoader>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { mapGetters } from "vuex";
import { actGetSearchData, actGetPopulerList } from "@/modules/search";

export default {
  data() {
    return {
      placeholderScreenData: {
        displayType: 'SQUARE',
      },
      routeSearchQuery: null,
      movieObj: null,
      searchedData: null,
      searchedSeriesData: null,
      searchedMoviesData: null,
      categorizedSeries: null,
      categorizedMovies: null,
      categorizedContents: null,
      mainSearchedContents: [],
      searchedContents: [],
      searchDatabase: "",
      popularSearch: [],
      dataFetched: false,
      isHeaderVisible: true,
      contentDataShow: true,
      localDisplayLang: "",
    };
  },
  computed: {
    ...mapGetters(["getSearchdata"]),
    fetchCardType() {
      if (screen.width < 576) {
        return {
          cardName: "Episode",
          type: "LANDSCAPE",
          height: "auto",
          width: "100%",
          quality: "THUMBNAIL",
          playButton: { enablePlay: false, height: "40px", width: "40px" },
        };
      } else if (screen.width < 576) {
        return {
          cardName: "Episode",
          type: "LANDSCAPE",
          height: "auto",
          width: "100%",
          quality: "THUMBNAIL",
          playButton: { enablePlay: false, height: "40px", width: "40px" },
        };
      } else {
        return {
          cardName: "Episode",
          type: "LANDSCAPE",
          height: "auto",
          width: "100%",
          quality: "THUMBNAIL",
          playButton: { enablePlay: false, height: "40px", width: "40px" },
        };
      }
    },
  },
  created() {
    eventBus.$on("header-visible", (data) => {
      this.isHeaderVisible = data;
    });

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    (this.localDisplayLang =
      this.localDisplayLang == "hi" ? "hin" : this.localDisplayLang),
      (this.routeSearchQuery = this.$route.params.query);

    //this.fetchSearchedQueryData();

    let payload = {
      endpoint: "subscriber/v1/content",
      params: {
        category: "",
        genre: "",
        displaylanguage: this.localDisplayLang,
        objecttype: "CONTENT",
        searchtype: "STRICT",
        subcategory: "",
        tags: "",
      },
    };

    actGetPopulerList(payload).then(
      (res) => {
        console.log("dataset---", res);
        this.dataFetched = true;
        this.popularSearch = res.dataset;
        // this.popularSearch.splice(3,)
      },
      (error) => {}
    );
  },
  mounted() {
    eventBus.$on("clearSearch", () => {
      this.searchDatabase = null;
    });
    eventBus.$on("render-search-to-dom", this.showSearchedData);
  },

  watch: {
    getSearchdata(val) {
      if (this.searchDatabase && this.searchDatabase.length <= 15) {
        setTimeout(() => {
          this.initIntersector();
        }, 300);
      }
      console.log("updated search data", val);
      this.searchDatabase = val;
    },
  },
  methods: {
    initIntersector() {
      const placeholder = document.querySelector("#intersectPlaceholder");
      const options = {
        root: null,
        threshold: 0.2,
      };
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            eventBus.$emit("pagination-active");
          }
        });
      }, options);
      
      if (placeholder) {
        observer.observe(placeholder);
      } else {
        console.error("Observer not found");
      }
    },
    initialRoute() {
      this.$router.push({ name: "Home" });
    },

    //search based on keyword
    fetchSearchedQueryData() {
      let payload = {
        query: this.routeSearchQuery,
        displaylanguage: this.localDisplayLang,
        pagesize: 100,
      };

      let subscriberProfileDetails = localStorage.getItem(
        "subscriberProfileDetails"
      );

      if (subscriberProfileDetails) {
        subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
      }

      if (
        subscriberProfileDetails &&
        subscriberProfileDetails.kidsmode === "YES"
      ) {
        payload.genre = "Kids";
      }

      eventBus.$emit("search-api-call", payload);
    },

    //display Searched Data
    showSearchedData(payload) {
      this.categorizedContents = null;
      this.searchedData = payload.data;
      this.searchedSeriesData = this.searchedData.filter((element) => {
        return element.objecttype === "SERIES";
      });
      this.categorizedSeries = [
        { title: "SERIES", objects: this.searchedSeriesData },
      ];
      this.searchedMoviesData = this.searchedData.filter((element) => {
        return element.objecttype === "CONTENT";
      });
      this.categorizedMovies = [
        { title: "MOVIES", objects: this.searchedMoviesData },
      ];
      this.categorizedContents = [
        ...this.categorizedSeries,
        ...this.categorizedMovies,
      ];
    },

    //if already present in search page
    getSearchedData(data) {
      this.categorizedContents = null;
      let payload = {
        query: data,
        displaylanguage: this.localDisplayLang,
        pagesize: 100,
      };

      let subscriberProfileDetails = localStorage.getItem(
        "subscriberProfileDetails"
      );

      if (subscriberProfileDetails) {
        subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
      }

      if (
        subscriberProfileDetails &&
        subscriberProfileDetails.kidsmode === "YES"
      ) {
        payload.genre = "Kids";
      }
      eventBus.$emit("search-page-call", payload);
    },
  },
  beforeDestroy() {
    eventBus.$emit("search-close", true);

    eventBus.$emit("enbled_header");
  },
  components: {
    detailPageLoader: () =>
      import(
        /* webpackChunkName: "detailPageLoader" */ "@/components/DetailPage/detailPageLoader.vue"
      ),
    categoryCard: () =>
      import(
        /* webpackChunkName: "categoryCard" */ "@/components/Templates/categoryCard.vue"
      ),
    SearchSlider: () =>
      import(/* webpackChunkName: "SearchSlider" */ "./SearchSlider.vue"),
    categoriesPlaceholder: () =>
      import(
        /* webpackChunkName: "categoriesPlaceholder" */ "@/components/placeholders/categoriesPlaceholder.vue"
      ),
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./SearchPage.scss";
</style>
